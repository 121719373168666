import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
// import Navigation from "../components/common/navigation/navigation";
import Navigation from "../components/common/navigation/navigation-new";
// import Header from "../components/sections/contact-us/header";
// import Inquiries from "../components/sections/home/inquiries";
import Footer from "../components/sections/footer/index-new";
import ContactDetails from "../components/sections/contact-us";

const ContactUs = () => {
  return (
    <Layout>
      <SEO
        title="Contact Us"
        description="OPay gives you the freedom to make quick and easy payments, earn money, spend smart, and save more."
      />
      <Navigation background="white" />
      {/* <Header /> */}
      <ContactDetails />
      {/* <Inquiries /> */}
      <Footer />
    </Layout>
  );
};

export default ContactUs;
